export default [
  {
    question: 'Which of these methods is the primary matrix for alcohol testing',
    correct: 0,
    answers: [
      'Breath',
      'Oral Fluid',
      'Urine',
      'Hair'
    ]
  },
  {
    question: 'Which methods can be used for longer-term detection of alcohol use?',
    correct: 3,
    answers: [
      'Breath',
      'Oral Fluid',
      'Urine',
      'Hair'
    ]
  },
  {
    question: 'What is the alcohol drink-drive limit in England, Wales and Northern Ireland?',
    correct: 2,
    answers: [
      '0.85mg/100ml',
      '0.5mg/100ml',
      '0.35mg/100ml',
      '0.05mg/100ml'
    ]
  },
  {
    question: 'How long, on average, does it take one unit of alcohol to leave the system?',
    correct: 2,
    answers: [
      '30 minutes',
      '45 minutes',
      '1 hour',
      '1.5 hours'
    ]
  },
  {
    question: 'When using a breathalyser device, why do we have it recalibrated at the correct intervals?',
    correct: 1,
    answers: [
      'To make sure the display is working correctly',
      'To make sure that the sensor is operating correctly',
      'To replace the battery',
      'To log the test frequency over the period'
    ]
  },
  {
    question: 'What is a cut-off level in the context of alcohol testing?',
    correct: 3,
    answers: [
      'The point at which alcohol becomes active',
      'The concentration at which the machine will not work beyond',
      'The data at which the product is out of calibration',
      'The concentration at which the alcohol level is deemed a fail'
    ]
  },
  {
    question: 'Choose the correct statement from these four:',
    correct: 1,
    answers: [
      'BAC is Brain Alcohol Content, BrAC is Brew Alcohol Content',
      'BAC is Blood Alcohol Content, BrAC is Breath Alcohol Content',
      'BAC is Body Alcohol Content, BrAC is Breath Alcohol Content',
      'BAC is Beer Alcohol Content, BrAC is Brandy Alcohol Content'
    ]
  },
  {
    question: 'Why should the donor not tale anything by mouth for 15 minutes before testing?',
    correct: 1,
    answers: [
      'To avoid getting food in the device',
      'To avoid blowing any neat alcohol into the device',
      'So that it is unlikely there will be excess oral fluid in the donor\'s mouth',
      'To let the blood level stabilise'
    ]
  },
  {
    question: 'What is the best posture to give a breath sample?',
    correct: 1,
    answers: [
      'Sitting and leaning forward on a desk',
      'Sitting or standing with a straight back and relaxed shoulders',
      'Reclined on a medical couch',
      'Whilst walking'
    ]
  },
  {
    question: 'In the case of any alcohol being detected, what should the next course of action be?',
    correct: 1,
    answers: [
      'Make the donor a cup of coffee',
      'Wait 15 minutes and take a second test',
      'Dismiss the donor if they are below the site limit',
      'Change the mouthpiece on the instrument and re-test'
    ]
  }
]
