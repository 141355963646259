import jsPdf from 'jspdf'
import SignatureSrc from './img/jpg/signature.jpg'
import LogoSrc from './img/png/surescreen-diagnostics-logo.png'

export default function downloadPDF (name) {
  let pdf = new jsPdf({ orientation: 'landscape', format: 'a4' })

  // Make the page border with two squares
  pdf.setFillColor('#61116a')
  pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F')
  pdf.setFillColor('#ffffff')
  pdf.rect(5, 5, pdf.internal.pageSize.width - 10, pdf.internal.pageSize.height - 10, 'F')

  // Add the logo at the top left
  let logo = new Image()
  logo.src = LogoSrc
  pdf.addImage(logo, 'PNG', 10, 10, 81, 13.2)

  // Header text / page title
  pdf.setFontSize(16)
  pdf.setTextColor('#3a3a3a')
  pdf.setFontStyle('bold')
  pdf.text('Certificate of SureScreen Alcohol Screening Competency', pdf.internal.pageSize.width - 10, 19, {
    align: 'right'
  })

  pdf.setFontStyle('normal')

  pdf.setLineHeightFactor(2)

  pdf.setFontSize(18)
  pdf.text('This document certifies that', pdf.internal.pageSize.width / 2, 80, {
    maxWidth: pdf.internal.pageSize.width * 0.66,
    align: 'center'
  })

  pdf.setFontSize(25)
  pdf.setFontStyle('italic')
  pdf.text(name, pdf.internal.pageSize.width / 2, pdf.internal.pageSize.height / 2 - 10, {
    maxWidth: pdf.internal.pageSize.width * 0.66,
    align: 'center'
  })

  pdf.setFontStyle('normal')
  pdf.setFontSize(18)
  pdf.text(
    `has demonstrated competence in the SureScreen Diagnostics alcohol screening theory test.`,
    pdf.internal.pageSize.width / 2,
    107.5,
    { maxWidth: pdf.internal.pageSize.width * 0.66, align: 'center' }
  )

  pdf.setFontSize(16)
  pdf.text(`Date of Issue: ${new Date().toLocaleDateString()}`, 10, pdf.internal.pageSize.height - 42.5)

  pdf.text(`Date of Expiry: ${new Date(new Date().setFullYear(new Date().getFullYear() + 2)).toLocaleDateString()}`, 10, pdf.internal.pageSize.height - 32.5)

  let signature = new Image()
  signature.src = SignatureSrc
  pdf.addImage(signature, 'JPG', 10, pdf.internal.pageSize.height - 27.5, 54, 8.8)
  pdf.text('David Campbell, Director', 10, pdf.internal.pageSize.height - 10)

  pdf.text(
    'info@surescreen.com | www.surescreen.com',
    pdf.internal.pageSize.width - 10,
    pdf.internal.pageSize.height - 10,
    { align: 'right' }
  )

  pdf.save(
    `${name
      .split(' ')
      .join('-')
      .toLowerCase()}-surescreen-alcohol-screening-theory-competency-certificate.pdf`
  )
}
